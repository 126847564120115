import { css } from '@amedia/brick-tokens';

export const bubbleTeaserStyle = css({
  '--_b-opinion-color-bg':
    'var(--b-teaser-color-bg, var(--brick-colors-supportiveNoneBg, #fff))',
  '--_b-opinion-color-fg':
    'var(--b-teaser-color-fg, var(--brick-colors-supportiveNoneFg, 000))',
  '--_b-opinion-padding':
    'var(--brick-space-teaserYInsetM) var(--brick-space-teaserXInsetM)',
  backgroundColor: 'var(--_b-opinion-color-bg)',
  color: 'var(--_b-opinion-color-fg)',

  $$avatar: '$sizes$avatarM',
  $$arrow: 'calc(($space$teaserSkinInsetM / 4) + ($$avatar / 6))',
  display: 'grid',
  gridTemplateColumns: 'auto auto $premiumIconM',
  gridTemplateRows: 'auto $$arrow $$avatar',
  gridTemplateAreas:
    '"bubble bubble bubble" "arrow . ." "byline byline premium"',
  padding:
    'var(--b-teaser-padding-opinion-bubble-wrap, var(--_b-opinion-padding))',

  '@bp532': {
    $$arrow: 'calc(($space$teaserSkinInsetL / 4) + ($$avatar / 6))',
    gridTemplateColumns: 'auto auto $premiumIconL',
    '--_b-opinion-padding':
      'var(--brick-space-teaserYInsetL) var(--brick-space-teaserXInsetL)',
  },

  '& *': {
    minWidth: 0,
  },
});

//the object bubbleLayout are used to generate css for inline injection by scoponok
export const bubbleLayout = {
  variants: {
    version: {
      imageLeft: {
        columnGap: '$teaserStackAM',
        rowGap: '$teaserStackBM',
        gridTemplateColumns: 'calc(34% + 3%) 1fr',
        gridTemplateRows: 'auto',
        gridTemplateAreas: '"image title" "image title"',
        '&.mirror': {
          gridTemplateColumns: '1fr calc(34% + 3%)',
          gridTemplateAreas: '"title image" "title image"',
        },
        '&.pills': {
          gridTemplateAreas: '"image pills" "image title"',
          gridTemplateRows: 'auto',
        },
        '&.pills.mirror': {
          gridTemplateAreas: '"pills image" "title image"',
          gridTemplateRows: 'auto',
        },
        gridFlow: 'row',
        position: 'relative',
        '& figure': {
          gridArea: 'image',
        },
        '& .title_container': {
          gridArea: 'title',
          boxSizing: 'border-box',
        },
        '& .bubble-pills': {
          paddingTop: '0px',
          '@bp532': {
            paddingTop: '0px',
          },
        },
        '@bp532': {
          columnGap: '$teaserStackAL',
          rowGap: '$teaserStackBL',
        },
      },
    },
  },
};

export const bubbleLayoutStyle = css({
  gridArea: 'bubble',
  borderRadius: '$teaserImage',

  '&.pills': {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridTemplateAreas: '"pills" "title"',
    gridRowGap: '$teaserStackBM',
    '@bp532': {
      gridRowGap: '$teaserStackBL',
    },
  },
});

export const bubbleImageLayoutStyle = css({
  gridArea: 'bubble',
  display: 'grid',

  columnGap: '$teaserStackAM',
  rowGap: '$teaserStackBM',
  gridTemplateAreas: '"image" "title"',
  borderRadius: '$teaserImage',

  '&.pills': {
    gridTemplateAreas: '"image" "pills" "title"',
    gridTemplateRows: 'auto 1.7em 1fr',
  },
  '& figure': {
    gridArea: 'image',
  },

  '& .bubble-pills': {
    paddingTop:
      'calc($teaserContentYInsetM + ($teaserStackAM - $teaserStackBM))',
    '@bp532': {
      paddingTop:
        'calc($teaserContentYInsetL + ($teaserStackAL - $teaserStackBL))',
    },
  },

  variants: {
    version: {
      imageLeft: {
        ...bubbleLayout.variants.version.imageLeft,
      },
      default: {},
    },
  },
});

export const bubbleStyle = css({
  '--_bubble-skin-bg': 'var(--brick-colors-teaserBubbleBg, #f1eded)',
  '--_bubble-skin-fg': 'var(--brick-colors-teaserBubbleFg, #000)',

  '--_b-bubble-color-bg':
    'var(--b-teaser-color-opinion-bubble-bg, var(--_bubble-skin-bg))',
  '--_b-bubble-color-fg': 'var(--b-teaser-color-fg, var(--_bubble-skin-fg))',

  '--_b-bubble-padding': 'var(--brick-space-teaserBubbleInsetM)',
  backgroundColor: 'var(--_b-bubble-color-bg)',
  color: 'var(--_b-bubble-color-fg)',
  padding: 'var(--b-teaser-padding-opinion-bubble, var(--_b-bubble-padding))',

  '@bp532': {
    '--_b-bubble-padding': 'var(--brick-space-teaserBubbleInsetL)',
  },

  variants: {
    skin: {
      sport: {},
      none: {
        '--_bubble-skin-bg': 'var(--brick-colors-teaserBubbleBg)',
        '--_bubble-skin-fg': 'var(--brick-colors-teaserBubbleFg)',
      },
      black: {
        '--_bubble-skin-bg': 'var(--brick-colors-supportiveBlackBg)',
        '--_bubble-skin-fg': 'var(--brick-colors-supportiveBlackFg)',
      },
      'custom-one': {
        '--_bubble-skin-bg':
          'var(--custom-background-color-one, var(--brick-colors-supportiveCustomOneBg))',
        '--_bubble-skin-fg':
          'var(--custom-background-color-one-front, var(--brick-colors-supportiveCustomOneFg))',
      },
      'custom-two': {
        '--_bubble-skin-bg':
          'var(--custom-background-color-two, var(--brick-colors-supportiveCustomTwoBg))',
        '--_bubble-skin-fg':
          'var(--custom-background-color-two-front, var(--brick-colors-supportiveCustomTwoFg))',
      },
      'custom-three': {
        '--_bubble-skin-bg':
          'var(--custom-background-color-three, var(--brick-colors-supportiveCustomThreeBg))',
        '--_bubble-skin-fg':
          'var(--custom-background-color-three-front, var(--brick-colors-supportiveCustomThreeFg))',
      },
      highlight: {
        '--_bubble-skin-bg': 'var(--brick-colors-supportiveHighlightBg)',
        '--_bubble-skin-fg': 'var(--brick-colors-supportiveHighlightFg)',
      },
      opinion: {
        '--_bubble-skin-bg':
          'var(--opinion-background-color, var(--brick-colors-supportiveOpinionBg))',
        '--_bubble-skin-fg':
          'var(--opinion-color-front, var(--brick-colors-supportiveOpinionFg))',
      },
    },
  },
});

export const titleContainerStyle = css({
  container: 'teaser-content / inline-size',

  gridArea: 'title',
  display: 'inline-grid',
  gap: '$teaserStackTitleM',
  paddingBottom: '$teaserContentYInsetM',

  //@media over 532
  '@bp532': {
    gap: '$teaserStackTitleL',
    paddingBottom: '$teaserContentYInsetL',
  },
});

export const bubblePillsStyle = css({
  gridArea: 'pills',
});

export const arrowDown = css({
  '--_b-avatar-size': 'var(--b-teaser-size-avatar, var(--brick-sizes-avatarM))',
  '--_bubble-skin-bg': 'var(--brick-colors-teaserBubbleBg, #f1eded)',
  '--_b-bubble-color-bg':
    'var(--b-teaser-color-opinion-bubble-bg, var(--_bubble-skin-bg))',
  '--_b-bubble-arrow-inset':
    'var(--b-teaser-placement-opinion-bubble-arrow, calc(var(--_b-avatar-size) / 3))',

  gridRow: '2',
  position: 'relative',
  bottom: 0,
  left: 'var(--_b-bubble-arrow-inset)',
  width: 0,
  height: 0,
  borderLeft: 'calc(var(--_b-avatar-size) / 6) solid transparent',
  borderRight: 'calc(var(--_b-avatar-size) / 6) solid transparent',
  borderTop: `calc(var(--_b-avatar-size) / 6) solid var(--_b-bubble-color-bg)`,

  variants: {
    skin: {
      sport: {},
      none: { '--_bubble-skin-bg': '$colors$teaserBubbleBg' },
      black: {
        '--_bubble-skin-bg': '$colors$supportiveBlackBg',
      },
      'custom-one': {
        '--_bubble-skin-bg':
          'var(--custom-background-color-one, $supportiveCustomOneBg)',
      },
      'custom-two': {
        '--_bubble-skin-bg':
          'var(--custom-background-color-two, $supportiveCustomTwoBg)',
      },
      'custom-three': {
        '--_bubble-skin-bg':
          'var(--custom-background-color-three, $supportiveCustomThreeBg)',
      },
      highlight: {
        '--_bubble-skin-bg': '$colors$supportiveHighlightBg',
      },
      opinion: {
        '--_bubble-skin-bg':
          'var(--opinion-background-color, $supportiveOpinionBg)',
      },
    },
  },
});

export const bubbleFooterStyle = css({
  gridArea: 'byline',
  boxSizing: 'border-box',
  gap: '$teaserStackBM',
  $$spaceBase: '$space$teaserSkinInsetM',
  paddingTop: 'calc($$spaceBase / 4)',
  alignSelf: 'end',

  '@bp532': {
    $$spaceBase: '$space$teaserSkinInsetL',
    gap: '$teaserStackBL',
  },

  '@bp680': {
    gap: '$teaserStackBL',
  },

  paddingBottom: 'var(--b-teaser-padding-opinion-bubble, 0)',
});

export const premiumWrapper = css({
  gridArea: 'premium',
  placeSelf: 'end',
  display: 'flex',

  paddingRight: 'var(--b-teaser-padding-opinion-bubble, 0)',
  paddingBottom: 'var(--b-teaser-padding-opinion-bubble, 0)',
});
